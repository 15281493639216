import React from 'react'
import { connect } from 'react-redux'
import { changeEndTime, initFetchMoreVenues, changeEdges } from '../../../../state/reduxApp'
import Select from 'react-select'
import ClockIcon from '../../../../../static/svgs/clock.svg'
import XIcon from '../../../../../static/svgs/x.svg'
import inputIconStyles from '../../../common/inputIconStyles.module.css'
import textInputStyles from '../../../common/textInput/textInput.module.css'
import '../../../common/reactSelectCustomStyles.css'
import { endtimeDisabled, endtimeHighlighted } from '../../../../services/searchService'
import { isEndtimeBeforeStarttime, isMeetingTimeLessThan2Hours } from '../../../../services/timeService'
import { trackFilterEvent } from '../../../../services/analyticsService'
import { injectIntl } from '../../../../../plugins/gatsby-plugin-intl-custom'

class EndTimeSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: null,
      hasFocus: false,
    }

    this.handleEndTimeChange = this.handleEndTimeChange.bind(this)
    this.handleEndTimeClear = this.handleEndTimeClear.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }

  handleEndTimeChange(event) {
    let option = listOfHours.find(event => event.value === listOfHours.value)
    this.setState({ selectedOption: option })
    if (!event) return

    this.props.dispatchEndTime(event.value)
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    if (this.props.changeUserMessage) this.props.changeUserMessage(undefined)
    trackFilterEvent('endTime', event.value)
  }

  handleEndTimeClear() {
    this.setState({ selectedOption: null })
    this.props.dispatchEndTime('')
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    if (this.props.changeUserMessage) this.props.changeUserMessage(undefined)
    trackFilterEvent('endTime', '')
  }

  handleOnFocus() {
    this.setState({ hasFocus: true })
  }

  handleOnBlur() {
    this.setState({ hasFocus: false })
  }

  isDisabled() {
    // For using input in venue page
    if (this.props.isDisabled !== undefined) {
      return this.props.isDisabled
    }

    return endtimeDisabled(this.props.reduxSearch)
  }

  renderClearIcon() {
    return <XIcon className={inputIconStyles.clearIcon} onClick={this.handleEndTimeClear} />
  }

  render() {
    // React-Select component provides it's own API for styling. For more information about custom styles
    // you should check out their site: https://react-select.com/styles
    const customStyles = {
      valueContainer: () => ({
        boxShadow: 'none',
        paddingTop: '1.25rem',
        paddingBottom: '0.3rem',
      }),
      control: (base, state) => ({
        ...base,
        borderRadius: '3px',
        border: '1px solid #D2D2D2',
        position: 'relative',
        boxShadow: state.isFocused ? '0 0 5px 0 rgba(132,132,132,0.8)' : '',
        '&:hover': {
          border: '1px solid #D2D2D2',
        },
      }),
      input: base => ({
        ...base,
        fontSize: '1rem',
        lineHeight: '1.25em',
        boxShadow: 'none',
        color: '#000',
        margin: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginLeft: '34px',
      }),
      singleValue: base => ({
        ...base,
        fontSize: '1rem',
        lineHeight: '1.25em',
        boxShadow: 'none',
        paddingTop: '1rem',
        paddingBottom: '0',
        marginLeft: '34px',
      }),
      menu: base => ({
        ...base,
        borderRadius: '0',
        margin: '0',
        zIndex: '4',
      }),
      option: (base, state) => ({
        ...base,
        color: '#000',
        fontSize: '0.8em',
        backgroundColor: state.isFocused ? '#f2f2f2' : '#fff',
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
    }

    const clearIcon = this.renderClearIcon()

    const temp = listOfHours.find(hour => {
      if (hour.value === this.props.startTime) {
        return hour
      }
      return null
    })
    let endHours = listOfHours
    let index

    if (temp && temp.num !== undefined && temp.num !== null) endHours.slice(temp.num)
    if (temp && temp.num !== undefined && temp.num !== null) {
      // Venues has to be booked at least 2 hours so assigning index as the value temp.num + 4
      // the index gives a desired number that can be used pointing to the correct endHour index in the array
      // note that this all is handled as a hardcode, but given that the opening hours does not vary the implementation should be fine for now.
      index = temp.num + 4
      endHours = endHours.slice(index)
    }

    return (
      <div className={textInputStyles.inputWrapper}>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={endHours}
          styles={customStyles}
          placeholder={false}
          value={{ value: this.props.endTime, label: this.props.endTime }}
          onChange={this.handleEndTimeChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          isDisabled={this.isDisabled()}
          defaultMenuIsOpen={false}
        />
        <ClockIcon alt="Clock icon" className={inputIconStyles.mainIcon} />
        {/* For debugging you add defaultMenuIsOpen={true} to the component */}
        <label className={[textInputStyles.floatingLabel, this.props.endTime || this.state.hasFocus ? textInputStyles.transform : ''].join(' ')}>
          {this.props.intl.formatMessage({ id: 'search.endTime' })}
        </label>
        {this.props.endTime ? clearIcon : ''}

        {endtimeHighlighted(this.props.reduxSearch) && !isEndtimeBeforeStarttime(this.props.reduxSearch) && !isMeetingTimeLessThan2Hours(this.props.reduxSearch) ? (
          <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'search.addEndtimeOrRemoveStarttime' })}</span>
        ) : (
          ''
        )}
        {endtimeHighlighted(this.props.reduxSearch) && isEndtimeBeforeStarttime(this.props.reduxSearch) ? (
          <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'search.endTimeBeforeStarttime' })}</span>
        ) : (
          ''
        )}
        {endtimeHighlighted(this.props.reduxSearch) && !isEndtimeBeforeStarttime(this.props.reduxSearch) && isMeetingTimeLessThan2Hours(this.props.reduxSearch) ? (
          <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'search.minimumDurationForEvent' })}</span>
        ) : (
          ''
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    startTime: state.search.startTime,
    endTime: state.search.endTime,
    searchBoxText: state.search.boxText,
    reduxSearch: state.search,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchEndTime: callbackLink => dispatch(changeEndTime(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EndTimeSelector))

export const listOfHours = [
  { value: '08:00', label: '08:00', num: 0 },
  { value: '08:30', label: '08:30', num: 1 },
  { value: '09:00', label: '09:00', num: 2 },
  { value: '09:30', label: '09:30', num: 3 },
  { value: '10:00', label: '10:00', num: 4 },
  { value: '10:30', label: '10:30', num: 5 },
  { value: '11:00', label: '11:00', num: 6 },
  { value: '11:30', label: '11:30', num: 7 },
  { value: '12:00', label: '12:00', num: 8 },
  { value: '12:30', label: '12:30', num: 9 },
  { value: '13:00', label: '13:00', num: 10 },
  { value: '13:30', label: '13:30', num: 11 },
  { value: '14:00', label: '14:00', num: 12 },
  { value: '14:30', label: '14:30', num: 13 },
  { value: '15:00', label: '15:00', num: 14 },
  { value: '15:30', label: '15:30', num: 15 },
  { value: '16:00', label: '16:00', num: 16 },
  { value: '16:30', label: '16:30', num: 17 },
  { value: '17:00', label: '17:00', num: 18 },
  { value: '17:30', label: '17:30', num: 19 },
  { value: '18:00', label: '18:00', num: 20 },
  { value: '18:30', label: '18:30', num: 21 },
  { value: '19:00', label: '19:00', num: 22 },
  { value: '19:30', label: '19:30', num: 23 },
  { value: '20:00', label: '20:00', num: 24 },
  { value: '20:30', label: '20:30', num: 25 },
  { value: '21:00', label: '21:00', num: 26 },
  { value: '21:30', label: '21:30', num: 27 },
  { value: '22:00', label: '22:00', num: 28 },
  { value: '22:30', label: '22:30', num: 29 },
  { value: '23:00', label: '23:00', num: 30 },
  { value: '23:30', label: '23:30', num: 31 },
  { value: '24:00', label: '24:00', num: 32 },
]
