export const LOCALIZEDVENUEATTRIBUTES = {
  fi: {
    meetings: {
      Daylight: 'Luonnonvalo',
      CreativeSpace: 'Luova tila',
      RestaurantService: 'Ravintolapalvelut',
      Accessible: 'Esteetön',
    },
    sauna: {
      SwimmingPool: 'Uima-allas/Palju',
      Terrace: 'Parveke/Terassi',
      MeetingSpace: 'Soveltuu kokoustilaksi',
      FestiveSpace: 'Soveltuu juhlatilaksi',
    },
    celebration: {
      Daylight: 'Luonnonvalo',
      RestaurantService: 'Ravintolapalvelut',
      Band: 'Tilaan mahtuu bändi',
      Accessible: 'Esteetön',
      Stage: 'Esiintymislava',
    },
  },
  sv: {
    meetings: {
      Daylight: 'Natural light',
      CreativeSpace: 'Kreativ miljö',
      RestaurantService: 'Restaurang',
      Accessible: 'Tillgänglighetsanpassad',
    },
    sauna: {
      SwimmingPool: 'Pool/Badtunna',
      Terrace: 'Balkong/Terrass',
      MeetingSpace: 'Lämplig för möten',
      FestiveSpace: 'Lämplig för fester',
    },
    celebration: {
      Daylight: 'Natural light',
      RestaurantService: 'Restaurang',
      Band: 'Rum för ett band att uppträda',
      Stage: 'Scen',
      Accessible: 'Tillgänglighetsanpassad',
    },
  },
  en: {
    meetings: {
      Daylight: 'Natural light',
      CreativeSpace: 'Creative space',
      RestaurantService: 'Restaurant',
      Accessible: 'Accessible',
    },
    sauna: {
      SwimmingPool: 'Swimming pool/Hot tub',
      Terrace: 'Balcony/Terrace',
      MeetingSpace: 'Suitable for meetings',
      FestiveSpace: 'Suitable for parties',
    },
    celebration: {
      Daylight: 'Natural light',
      RestaurantService: 'Restaurant',
      Band: 'The premises fit a band',
      Stage: 'Stage',
      Accessible: 'Accessible',
    },
  },
  et: {
    meetings: {
      Daylight: 'Loomulik valgus',
      CreativeSpace: 'Loominguline ruum',
      RestaurantService: 'Restoran',
      Accessible: 'Takistusteta',
    },
    sauna: {
      SwimmingPool: 'Ujumisbassein/Kümblustünn',
      Terrace: 'Rõdu/Terrass',
      MeetingSpace: 'Sobib konverentsiruumiks',
      FestiveSpace: 'Sobib peoruumiks',
    },
    celebration: {
      Daylight: 'Loomulik valgus',
      RestaurantService: 'Restoran',
      Band: 'Ruumi mahub bänd',
      Stage: 'Esinemislava',
      Accessible: 'Takistusteta',
    },
  },
}
