import React from 'react'
import { connect } from 'react-redux'
import { changeStartTime, initFetchMoreVenues, changeEdges } from '../../../../state/reduxApp'
import Select from 'react-select'
import ClockIcon from '../../../../../static/svgs/clock.svg'
import XIcon from '../../../../../static/svgs/x.svg'
import inputIconStyles from '../../../common/inputIconStyles.module.css'
import textInputStyles from '../../../common/textInput/textInput.module.css'
import '../../../common/reactSelectCustomStyles.css'
import { starttimeDisabled, starttimeHighlighted } from '../../../../services/searchService'
import { trackFilterEvent } from '../../../../services/analyticsService'
import { injectIntl } from '../../../../../plugins/gatsby-plugin-intl-custom'

class StartTimeSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: null,
      hasFocus: false,
    }

    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.handleStartTimeClear = this.handleStartTimeClear.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }

  handleTimeChange(event) {
    let option = listOfHours.find(event => event.value === listOfHours.value)
    this.setState({ selectedOption: option })
    if (!event) return

    this.props.dispatchStartTime(event.value)
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    if (this.props.changeUserMessage) this.props.changeUserMessage(undefined)
    trackFilterEvent('startTime', event.value)
  }

  handleStartTimeClear() {
    this.setState({ selectedOption: null })
    this.props.dispatchStartTime('')
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
    if (this.props.changeUserMessage) this.props.changeUserMessage(undefined)
    trackFilterEvent('startTime', '')
  }

  handleOnFocus() {
    this.setState({ hasFocus: true })
  }

  handleOnBlur() {
    this.setState({ hasFocus: false })
  }

  isDisabled() {
    // For using input in venue page
    if (this.props.isDisabled !== undefined) {
      return this.props.isDisabled
    }

    return starttimeDisabled(this.props.reduxSearch)
  }

  renderClearIcon() {
    return <XIcon className={inputIconStyles.clearIcon} onClick={this.handleStartTimeClear} />
  }

  render() {
    // React-Select component provides it's own API for styling. For more information about custom styles
    // you should check out their site: https://react-select.com/styles
    const customStyles = {
      valueContainer: () => ({
        boxShadow: 'none',
        paddingTop: '1.25rem',
        paddingBottom: '0.3rem',
      }),
      control: (base, state) => ({
        ...base,
        borderRadius: '3px',
        border: '1px solid #D2D2D2',
        position: 'relative',
        boxShadow: state.isFocused ? '0 0 5px 0 rgba(132,132,132,0.8)' : '',
        '&:hover': {
          border: '1px solid #D2D2D2',
        },
      }),
      input: base => ({
        ...base,
        fontSize: '1rem',
        lineHeight: '1.25em',
        boxShadow: 'none',
        color: '#000',
        margin: '0',
        paddingTop: '0',
        paddingBottom: '0',
        marginLeft: '34px',
      }),
      singleValue: base => ({
        ...base,
        fontSize: '1rem',
        lineHeight: '1.25em',
        boxShadow: 'none',
        paddingTop: '1rem',
        paddingBottom: '0',
        marginLeft: '34px',
      }),
      menu: base => ({
        ...base,
        borderRadius: '0',
        margin: '0',
        zIndex: '4',
      }),
      option: (base, state) => ({
        ...base,
        color: '#000',
        fontSize: '0.8em',
        backgroundColor: state.isFocused ? '#f2f2f2' : '#fff',
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
    }

    const clearIcon = this.renderClearIcon()
    return (
      <div className={textInputStyles.inputWrapper}>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={listOfHours}
          styles={customStyles}
          placeholder={false}
          value={{ value: this.props.startTime, label: this.props.startTime }}
          onChange={this.handleTimeChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          isDisabled={this.isDisabled()}
          defaultMenuIsOpen={false}
        />
        <ClockIcon alt="Clock icon" className={inputIconStyles.mainIcon} />
        {/* For debugging you add defaultMenuIsOpen={true} to the component */}
        <label className={[textInputStyles.floatingLabel, this.props.startTime || this.state.hasFocus ? textInputStyles.transform : ''].join(' ')}>
          {this.props.intl.formatMessage({ id: 'search.startTime' })}
        </label>
        {this.props.startTime ? clearIcon : ''}
        {starttimeHighlighted(this.props.reduxSearch) ? <span className={textInputStyles.reminder}>{this.props.intl.formatMessage({ id: 'search.addStarttimeOrRemoveEndtime' })}</span> : ''}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    startTime: state.search.startTime,
    searchBoxText: state.search.boxText,
    reduxSearch: state.search,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchStartTime: callbackLink => dispatch(changeStartTime(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StartTimeSelector))

export const listOfHours = [
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
]
