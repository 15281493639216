import React from 'react'
import buttonStyles from './radioButton.module.css'

export const RadioButton = props => {
  return (
    <div className={buttonStyles.wrapper}>
      <label className={props.disabled ? buttonStyles.disabledContainer : buttonStyles.container}>
        {props.label}
        <input type="radio" checked={props.checked} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled} />
        <span className={buttonStyles.checkmark} />
      </label>
    </div>
  )
}

export default RadioButton
