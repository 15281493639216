import React, { Component } from 'react'
import TextInput from '../textInput/textInput'
import { connect } from 'react-redux'
import { changePersonCount, initFetchMoreVenues, changeEdges } from '../../../state/reduxApp'
import textInputStyles from '../textInput/textInput.module.css'
import inputIconStyles from '../inputIconStyles.module.css'
import PersonIcon from '../../../../static/svgs/person.svg'
import XIcon from '../../../../static/svgs/x.svg'
import { personCountDisabled, personCountHighlighted } from '../../../services/searchService'
import { trackFilterEvent } from '../../../services/analyticsService'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'

/*  Props
      -edge
*/
class PersonCountSelector extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
    this.handlePersonsChange = this.handlePersonsChange.bind(this)
    this.handlePersonsClear = this.handlePersonsClear.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handlePersonsChange(event) {
    // TODO: Prevent input e.g. 3-4+5
    if (!event) return
    event.preventDefault()
    let tempCount = Number(event.target.value)
    if (!tempCount) tempCount = 0
    if (tempCount < 0) tempCount = Math.abs(tempCount)

    this.props.dispatchPersonCount(tempCount)
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
  }

  handlePersonsClear(event) {
    event.preventDefault()
    this.props.dispatchPersonCount(0)
    this.props.dispatchEdges(undefined)
    this.props.dispatchInitFetchMoreVenues()
  }

  handleBlur(event) {
    trackFilterEvent('personCount', event.target.value)
    if (this.props.onBlur) this.props.onBlur()
  }

  renderClearIcon() {
    return this.props.personCount ? <XIcon className={inputIconStyles.clearIcon} onClick={this.handlePersonsClear} /> : ''
  }

  render() {
    const { personCount, edge } = this.props
    return (
      <div className={textInputStyles.inputWrapper}>
        <PersonIcon alt="Person icon" className={inputIconStyles.mainIcon} />
        <TextInput
          id="personCountId"
          type={'number'}
          active={personCount > 0 ? 1 : 0}
          label={this.props.intl.formatMessage({ id: 'search.persons' })}
          value={personCount === 0 ? '' : personCount}
          onChange={this.handlePersonsChange}
          onBlur={this.handleBlur}
          disabled={personCountDisabled(this.props.reduxSearch)}
          max={edge && edge.maxCapacity ? edge.maxCapacity : undefined}
          min={edge && edge.minCapacity ? edge.minCapacity : undefined}
        />
        {this.renderClearIcon()}
        {personCountHighlighted(this.props.reduxSearch) && <span className={textInputStyles.reminder}></span>}
        {edge && personCount < edge.minCapacity && (
          <span className={textInputStyles.reminder}>
            {this.props.intl.formatMessage({ id: 'search.minPersonCount' })} <strong>{edge.minCapacity}</strong>
          </span>
        )}
        {edge && personCount > edge.maxCapacity && (
          <span className={textInputStyles.reminder}>
            {this.props.intl.formatMessage({ id: 'search.maxPersonCount' })} <strong>{edge.maxCapacity}</strong>
          </span>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    personCount: state.search.personCount,
    reduxSearch: state.search,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchPersonCount: callbackLink => dispatch(changePersonCount(callbackLink)),
  dispatchInitFetchMoreVenues: callbackLink => dispatch(initFetchMoreVenues(callbackLink)),
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PersonCountSelector))
