import React from 'react'
import tableSelectButtonStyles from './tableSelectButton.module.css'

export const TableSelectButton = props => {
  return (
    <div className={tableSelectButtonStyles.wrapper}>
      {props.buttonDisabled ? <div className={tableSelectButtonStyles.buttonItemDisabled} /> : ''}
      <label className={tableSelectButtonStyles.container}>
        <div className={tableSelectButtonStyles.subContainer}>
          <span className={tableSelectButtonStyles.icon}>{props.icon}</span>
          <span className={tableSelectButtonStyles.value}>{props.value}</span>
        </div>
        <input type="radio" checked={props.checked} name={props.name} value={props.value} onChange={props.onChange} disabled={props.disabled} />
        <span className={tableSelectButtonStyles.checkmark} />
      </label>
    </div>
  )
}

export default TableSelectButton
